import styled from 'styled-components';
import { Heading as UnstyledHeading } from '~/components/BaseElements';
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export const Heading = styled(UnstyledHeading).attrs({
  size: 'h2',
})`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 2.25rem;
  font-weight: 300;
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  text-rendering: optimizeLegibility;
`;

export const P = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  text-align: center;
`;

export const P2 = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  text-align: center;
  padding-top: 3rem;
`;

export const RedirectButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})``;

export const Section = styled(Box).attrs({
  component: 'section',
})`
  padding-bottom: 75px;
`;
