import React from 'react';
import { ExternalLink } from '~/components/BaseElements';
import { Trans, useTranslation } from 'react-i18next';
import * as Styled from './styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hero from '~/components/Hero/index';

export const Reports: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.Section py={0}>
      <Hero isSubPage>{t('Reports.title')}</Hero>
      <Box py={8}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Styled.P>
                <Trans i18nKey="Reports.fluTrendsParagraph">
                  <ExternalLink
                    data-test="flu_trends_link"
                    to="https://www.healthmap.org/flutrends/"
                  >
                    {t('Reports.fluTrendsPage')}
                  </ExternalLink>
                </Trans>
              </Styled.P>
              <Grid container justifyContent="center">
                <ExternalLink
                  data-test="flu_trends_button"
                  to="https://www.healthmap.org/flutrends/"
                >
                  <Styled.RedirectButton>
                    {t('Reports.fluTrendsPage')}
                  </Styled.RedirectButton>
                </ExternalLink>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Styled.Section>
  );
};
