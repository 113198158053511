import React from 'react';
import SEO from '~/components/seo';
import { Reports as ReportsContent } from '~/components/Reports/index.tsx';

const Reports = props => (
  <>
    <SEO title="Reports.title" pageContext={props.pageContext} />
    <ReportsContent />
  </>
);

export default Reports;
